import ItemGrid from './components/ItemGrid';
import { Heading2 } from './components/Typography';

const App = () => {
  return (
    <div id='preact_root' className='bg-white text-black h-screen py-20 px-24'>
      <Heading2 className='text-center'>KIOSKI CENTRE</Heading2>
      <div className='mt-24'>
        <ItemGrid />
      </div>
    </div>
  );
};

export default App;
