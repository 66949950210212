// eslint-disable-next-line import/named
import { ComponentChildren } from 'preact';

interface ITypography {
  children?: ComponentChildren;
  className?: string;
  dangerouslySetInnerHTML?: { __html: string };
}

const TypographyBase = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <span className={`${className}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </span>
);

export const Heading2 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-4xl sm:text-5xl lg:text-6xl`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);

export const Heading3 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-3xl sm:text-4xl lg:text-5xl`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);

export const Heading4 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-2xl sm:text-3xl lg:text-4xl`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);

export const BodyText1 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-xl sm:text-2xl lg:text-3xl`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);

export const BodyText2 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-lg sm:text-xl lg:text-2xl`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);

export const BodyText3 = ({ children, className, dangerouslySetInnerHTML }: ITypography) => (
  <TypographyBase className={`${className} text-sm sm:text-base lg:text-lg`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
    {children}
  </TypographyBase>
);
