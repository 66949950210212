export interface IItem {
  visibilityOrder: number;
  name: string;
  price: number;
}

export type TItems = IItem[];

const items: TItems = [
  {
    visibilityOrder: 1,
    name: 'makkaraperunat',
    price: 10.5,
  },
  {
    visibilityOrder: 2,
    name: 'makkaraperunat + ⅓ juoma',
    price: 11.5,
  },
  {
    visibilityOrder: 3,
    name: 'pitakebab',
    price: 8.9,
  },
  {
    visibilityOrder: 4,
    name: 'pitakebab + ⅓ juoma',
    price: 10.9,
  },
  {
    visibilityOrder: 7,
    name: 'kebab-muki',
    price: 6.9,
  },
  {
    visibilityOrder: 8,
    name: 'kebab-muki + ⅓ juoma',
    price: 7.9,
  },
  {
    visibilityOrder: 9,
    name: 'isot ranskalaiset',
    price: 4.5,
  },
  {
    visibilityOrder: 18,
    name: 'mozzarella-tikut (3 kpl)',
    price: 2.5,
  },
  {
    visibilityOrder: 19,
    name: 'mozzarella-tikut (6 kpl)',
    price: 3.5,
  },
  {
    visibilityOrder: 10,
    name: 'crispy chicken (4 kpl)',
    price: 4,
  },
  {
    visibilityOrder: 11,
    name: 'crispy chicken (8 kpl)',
    price: 7,
  },
  {
    visibilityOrder: 17,
    name: 'ciabatta',
    price: 4.5,
  },
  {
    visibilityOrder: 5,
    name: 'kebab ranskalaisilla',
    price: 10.5,
  },
  {
    visibilityOrder: 6,
    name: 'kebab ranskalaisilla + ⅓ juoma',
    price: 11.5,
  },
  {
    visibilityOrder: 12,
    name: 'hotdog pekonilla',
    price: 3.5,
  },
  {
    visibilityOrder: 14,
    name: 'pekonimakkara',
    price: 2.5,
  },
  {
    visibilityOrder: 15,
    name: 'grillimakkara',
    price: 1.5,
  },
];

export default items;
