import { useEffect, useState } from 'preact/hooks';

import items, { TItems } from '../data/items';
import { capitalize, formatPrice } from '../utils';
import DottedLine from './DottedLine';
import { Heading3 } from './Typography';

const ItemGrid = () => {
  const [itemsSorted, setItemsSorted] = useState<TItems>([]);

  useEffect(() => {
    items.sort;
    const sorted = items.sort((a, b) => {
      if (a.visibilityOrder < b.visibilityOrder) {
        return -1;
      } else if (a.visibilityOrder > b.visibilityOrder) {
        return 1;
      } else {
        return 0;
      }
    });
    setItemsSorted(sorted);
  }, []);

  return (
    <div className='columns-2 gap-20'>
      {itemsSorted.map((item, index) => (
        <div className='flex mb-8'>
          <div>
            <Heading3 className={index < 9 ? 'font-bold' : ''}>{capitalize(item.name)}</Heading3>
          </div>
          <DottedLine />
          <div>
            <Heading3 className={index < 9 ? 'font-bold' : ''}>{formatPrice('fi', item.price)} €</Heading3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemGrid;
