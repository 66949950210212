export const formatPrice = (locale: string, price: number) => {
  return price.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const capitalize = (s: string) => {
  return `${s[0].toUpperCase()}${s.slice(1)}`;
};
